import { Autocomplete as MUIAutocomplete, Chip } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { FC, forwardRef } from "react";

import TextField from "../../atoms/@form/TextField";
import SvgIcon from "../../atoms/SvgIcon";
import Props from "./props";
import { useStyles } from "./styles";

const renderOption = (props: any, option: any, { inputValue }: any) => {
  const matches = match(option.title, inputValue, { insideWords: true });
  const parts = parse(option.title, matches);

  return (
    <li {...props}>
      <div>
        {parts.map((part: any, index: any) => (
          <span
            key={index}
            style={
              part.highlight
                ? {
                    color: part.highlight,
                  }
                : {}
            }>
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
};

const renderInput = (props: any, propsTextField: any) => (
  <TextField
    {...props}
    variant="standard"
    label="Label Text"
    placeholder=""
    {...propsTextField}
    aria-autocomplete="none"
    onKeyDown={(event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
      }
    }}
    inputProps={{
      ...props.inputProps,
      autoComplete: "disable",
    }}
  />
);

const Autocomplete: FC<Props> = forwardRef(
  ({ options = [], formControlField, propsTextField, ...rest }, ref) => {
    const { classes } = useStyles();

    const onChange = (_: any, data: any, field: any) => {
      const uniqueData = Array.from(
        new Set(data.map((item: any) => item.title)),
      ).map((title) => data.find((item: any) => item.title === title));
      field.onChange(uniqueData);
    };

    const filterOptions = (options: any, state: any) => {
      const validOptions = options.filter(
        (option: any) =>
          option?.title !== null &&
          option?.title !== undefined &&
          option?.title !== "",
      );

      return validOptions.filter((option: any) =>
        option.title.toLowerCase().includes(state.inputValue.toLowerCase()),
      );
    };

    return (
      <MUIAutocomplete
        isOptionEqualToValue={(option: any, value: any) =>
          option?.title === value?.title
        }
        multiple
        className={classes.root}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: any, index: number) => (
            <Chip
              {...getTagProps({ index })}
              key={index}
              className={classes.chip}
              deleteIcon={<SvgIcon name="close" width={18} height={18} />}
              label={option.title}
            />
          ))
        }
        popupIcon={<SvgIcon name="chevronDown" width={18} height={18} />}
        classes={{ paper: classes.paper }}
        options={options}
        getOptionLabel={(option: any) => option.title}
        renderOption={renderOption}
        filterOptions={filterOptions}
        renderInput={(props: any) => renderInput(props, propsTextField)}
        clearIcon={<></>}
        {...rest}
        {...(formControlField && {
          onChange: (_, data) => {
            onChange(_, data, formControlField);
          },
        })}
        ref={ref}
      />
    );
  },
);

Autocomplete.displayName = "Autocomplete";

export default Autocomplete;
