import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { useApiRequest } from "../../../hooks/useApiRequest";
import { StateAuth } from "../../../middleware/api/auth/stateEntity/reducer";
import { API_ENDPOINTS } from "../../../middleware/api/endpoints.api";
import { AppState } from "../../../store/rootReducer";
import useForm from "../../atoms/@form/useForm";
import CaptchaConsent from "../../atoms/CaptchaConsent";
import { utilsIntercom } from "../../atoms/ThirdPartyLibraries/components/Intercom/utils";
import Authorization from "../../templates/Authorization";
import { usePackageAndPeriod } from "../PageSubscriptions/hooks/usePackageAndPeriod";
import { PathPage } from "../pathPage";
import SectionDescribesBusiness from "./components/SectionDescribesBusiness";
import SectionGoals from "./components/SectionGoals";
import SectionHeader from "./components/SectionHeader";
import { validationSchema } from "./form";
import Props from "./props";
import { useStyles } from "./styles";

const PageSignUpSurvey: FC<Props> = () => {
  const { boot, update, getVisitorId } = useIntercom();
  const { classes } = useStyles();
  const { user } = useSelector<AppState, StateAuth>((state) => state.auth);

  const { packageId, period } = usePackageAndPeriod();

  const form = useForm({
    defaultValues: {
      // position: "",
      // company: "",
      goals: [],
      business: "",
      businessOther: "",
      goalsOther: "",
      // beta: "",
      // isConsent: false,
      recaptcha: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const [requestState, sendRequest] = useApiRequest<any, any>(
    API_ENDPOINTS.AUTH.SURVEY,
    "POST",
  );

  const { state: email }: { state: string } = useLocation();

  const sendIntercom = async (data: any) => {
    if (!user) return;
    const userId = getVisitorId();

    const goals = data.goals.map((goal: any) =>
      goal === "Other" ? data.goalsOther : goal,
    );

    const intercomArr = {
      user_data: {
        user_hash: user.hashIntercom,
        anonymous_id: userId,
        user_id: `app_familyofficelist_${user.id}`,
        email: user.email,
        phone: user.phoneNumber,
        phone_number: user.phoneNumber,
        title: user.title,
        name: `${user.firstName} ${user.lastName}`,
        "Company Business": (data.business = "Other"
          ? data.businessOther
          : data.business),
        Goals: goals.join(", "),
      },
    };

    await utilsIntercom.pingIntercom(intercomArr);
  };

  const onSubmit = (data: any) => {
    data.email = email;
    sendRequest({ ...data })
      .then(() => {
        sendIntercom(data);
        navigate(PathPage.Subscriptions, { state: { packageId, period } });
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
      });
  };

  return (
    <Authorization className={classes.root}>
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <SectionHeader />
        <SectionDescribesBusiness form={form} />
        <SectionGoals form={form} />
        <CaptchaConsent
          form={form}
          buttonTitle={"Submit"}
          isLoading={requestState.isLoading}
        />
      </Box>
    </Authorization>
  );
};

export default PageSignUpSurvey;
