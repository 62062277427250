import { AxiosRequestConfig } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";

import { Dictionary } from "../i18n/types";
import { PaginationDto } from "../middleware/@types/pagination.type";
import { http } from "../middleware/http";
import { removeCacheItem, updateCacheItem } from "../utils/cache.util";

export type RequestMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type RestApiRequestData = Record<string, any> | undefined;
type RestApiResponseData = Record<string, any>;
type RestApiConfig = AxiosRequestConfig | undefined;

interface ApiState<T> {
  isLoading: boolean;
  error?: keyof Dictionary["errors"];
  data: T | null;
}

const fetchData = async <
  T extends RestApiResponseData,
  R extends RestApiRequestData,
>(
  apiPath: string,
  method: RequestMethod,
  data?: R,
  config?: RestApiConfig,
): Promise<T> => {
  switch (method) {
    case "DELETE":
      return await http.delete(apiPath);
    case "GET":
      return await http.get(apiPath);
    case "POST":
      return await http.post(apiPath, data, config);
    case "PUT":
      return await http.put(apiPath, data, config);
    case "PATCH":
      return await http.patch(apiPath, data, config);
    default:
      throw new Error(`Unsupported request method: ${method}`);
  }
};

const setCachedResponse = <T extends RestApiResponseData>(
  apiPath: string,
  data: T,
  enableCache: boolean,
  ttl: number = 3600000, // Default 1 hour in milliseconds
): void => {
  if (enableCache) {
    updateCacheItem(apiPath, data, ttl);
  }
};

const getCachedResponse = <T extends RestApiResponseData>(
  apiPath: string,
  enableCache: boolean,
): T | null => {
  if (!enableCache) return null;
  const cachedItem = sessionStorage.getItem(apiPath);
  if (cachedItem) {
    const item = JSON.parse(cachedItem);
    if (Date.now() > item.expiry) {
      removeCacheItem(apiPath);
      return null;
    }
    return item.data;
  }
  return null;
};

interface PaginationMeta {
  page: number;
  limit: number;
  total: number;
  totalPages: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

interface ExtendedApiState<T> extends ApiState<T> {
  paginationMeta?: PaginationMeta;
}

export function useApiRequest<
  T extends RestApiResponseData,
  R extends RestApiRequestData = undefined,
  C extends RestApiConfig = undefined,
>(
  apiPath: string,
  method: RequestMethod,
  enableCache = false,
  config?: RestApiConfig,
  initialPagination?: PaginationDto,
) {
  const [state, setState] = useState<ExtendedApiState<T>>({
    isLoading: false,
    data: getCachedResponse<T>(apiPath, enableCache),
    error: undefined,
  });

  const [paginationParams, setPaginationParams] = useState<
    PaginationDto | undefined
  >(initialPagination);

  const sendRequest = useCallback(
    async (
      requestData?: R,
      newApiPath?: string,
      newPaginationParams?: Partial<PaginationDto>,
    ): Promise<T | null | undefined> => {
      let fullApiPath = newApiPath || apiPath;

      if (paginationParams || newPaginationParams) {
        const updatedPaginationParams = {
          ...paginationParams,
          ...newPaginationParams,
        };
        setPaginationParams(updatedPaginationParams);
        fullApiPath += `?${new URLSearchParams(
          updatedPaginationParams as any,
        ).toString()}`;
      }

      const cachedData = getCachedResponse<T>(fullApiPath, enableCache);
      if (cachedData) {
        setState({ isLoading: false, data: cachedData, error: undefined });
        return cachedData;
      }

      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const response = await fetchData<T, R>(
          fullApiPath,
          method,
          requestData,
          config,
        );
        let newState: ExtendedApiState<T>;
        if (
          typeof response?.data === "object" &&
          "items" in response.data &&
          "meta" in response.data
        ) {
          newState = {
            error: undefined,
            isLoading: false,
            data: response.data.items,
            paginationMeta: response.data.meta,
          };
        } else {
          newState = {
            error: undefined,
            isLoading: false,
            data: response?.data,
          };
        }
        setState(newState);
        setCachedResponse<T>(fullApiPath, response?.data, enableCache);
        return newState.data;
      } catch (error: any) {
        const errorMessage =
          error?.response?.data?.error?.message || "serverError";
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          error: errorMessage,
        }));
        throw error;
      }
    },
    [apiPath, method, config, enableCache, paginationParams],
  );

  const updateData = useCallback(
    (data: Partial<T>) => {
      setState((prevState) => {
        const newData = {
          ...prevState.data,
          ...data,
        } as T;
        if (enableCache) {
          updateCacheItem(apiPath, newData);
        }
        return {
          ...prevState,
          data: newData,
        };
      });
    },
    [apiPath, enableCache],
  );

  const changePage = useCallback(
    (newPage: number) => {
      sendRequest(undefined, undefined, { page: newPage });
    },
    [sendRequest],
  );

  const isRequestSentRef = useRef(false);

  useEffect(() => {
    if (!initialPagination) return;
    if (isRequestSentRef.current) return;
    isRequestSentRef.current = true;
    sendRequest();
  }, [initialPagination, sendRequest]);

  return [state, sendRequest, updateData, changePage] as const;
}
