import { Box, InputLabel, TextField as MUITextField } from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef } from "react";

import Tooltip from "../../Tooltip";
import Props from "./props";
import { useStyles } from "./styles";

const TextField: FC<Props> = forwardRef(
  ({ children, className, tooltip, label, ...rest }, ref) => {
    const { classes } = useStyles();

    const InputLabelWithTooltip = (props: any) => (
      <Box>
        <InputLabel {...props} style={{ zIndex: 999999 }}>
          {label}
          <Tooltip iconName={"circleInfo"} title={tooltip || ""} arrow />
        </InputLabel>
      </Box>
    );

    return (
      <MUITextField
        maxRows={4}
        minRows={4}
        className={clsx(classes.root, className)}
        variant="standard"
        type="text"
        label={label}
        InputProps={{
          style: { resize: "none" },
          endAdornment: null,
        }}
        InputLabelProps={{
          component: tooltip ? InputLabelWithTooltip : undefined,
        }}
        {...rest}
        autoComplete="none"
        ref={ref}>
        {children}
      </MUITextField>
    );
  },
);
TextField.displayName = "TextField";

export default TextField;
