import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";

export type ClassNames =
  | "root"
  | "svgIcon"
  | "buttonOpen"
  | "buttonOpenSvg"
  | "buttonLogo"
  | "svgLogo"
  | "listItemButton"
  | "listItemButtonActive"
  | "listItemIcon"
  | "listItemText"
  | "list"
  | "drawerHeader";

const listItemButtonActive = {};

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    backgroundColor: COLORS.primary.darkGreen,
  },
  svgIcon: {
    color: COLORS.primary.orange,
    width: 24,
    height: 24,
  },
  buttonOpen: {
    zIndex: 9999,
    display: "flex",
    width: "26px",
    height: "26px",
    padding: "8px 12px",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: "-6px",
    top: "13px",
    borderRadius: "4px",
    background: COLORS.secondary.green,

    "&:hover": {
      background: COLORS.primary.green,
    },
  },
  buttonOpenSvg: {
    width: "18px",
    height: "18px",
    flexShrink: "0",
    color: COLORS.primary.grey30,
  },
  buttonLogo: {
    padding: 0,
    minHeight: 0,
  },
  list: {},

  listItemButtonActive: {
    background: COLORS.secondary.green,
    "& .MuiTypography-root": {
      color: `${COLORS.primary.orange} !important`,
    },
  },
  listItemButton: {
    width: "160px",
    height: "48px",
    borderRadius: "4px",

    "&:hover": {
      background: "rgba(66, 136, 132, 0.7)",
      "& .MuiTypography-root": {
        color: COLORS.primary.orange,
      },
    },

    "&:active": {
      background: "rgba(66, 136, 132, 0.9)",
      "& .MuiTypography-root": {
        color: COLORS.primary.orange,
      },
    },
  },

  listItemIcon: {
    minWidth: "unset",
  },
  listItemText: {
    paddingLeft: SPACING.s_8,
    "& .MuiTypography-root": {
      ...(theme.typography.h3 as any),
      color: COLORS.primary.grey40,
    },
  },
  svgLogo: {},
  drawerHeader: {
    minHeight: "0 !important",
    marginBottom: SPACING.s_30,
  },
}));
